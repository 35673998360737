<template>
  <div v-loading="loading" class="page">
    <div class="main-wrap flex">
      <!-- left-wrap start -->
      <div class="left-wrap flex flex-dc">
        <div class="menu-list-wrap flex flex-dc">
          <div class="tooltip-wrap">
            <el-input placeholder="请输入内容" v-model="search" clearable class="input-with-select">
              <i slot="prepend" class="el-icon-search"></i>
              <div slot="append" class="flex flex-ac">
                <i class="el-icon-sort pointer mr10"></i>
                <i class="el-icon-s-operation pointer"></i>
              </div>
            </el-input>
          </div>
          <div class="list-wrap flex flex-dc flex-1">
            <div class="list-group flex"
                 v-for="contact in simpleContactList" :key="contact.id"
                 :class=" {active: activeContactId && activeContactId === contact.id}"
                 @click="loadContact(contact)">
              <div class="img-wrap">
                <img v-if="contact.avatarUrl" :src="contact.avatarUrl">
                <img v-else src="@/assets/images/logo.png">
              </div>
              <div class="info-wrap flex-1 flex flex-dc">
                <div class="title">{{ contact.name }}</div>
                <div class="text">{{ contact.companyName }}</div>
                <div class="text">{{ contact.title }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- left-wrap end -->

      <!-- right-wrap start -->
      <div class="right-wrap flex-1">
        <div ref="scrollView" class="card-wrap flex flex-dc">
          <div class="card-group flex flex-dc">
            <div class="card-header flex flex-jb">
              <div class="header-left flex flex-ac">
                <img v-if="activeCompanyId && activeCompany.logo" :src="activeCompany.logo"/>
                <img v-else src="@/assets/images/logo.png"/>
                <span class="company-name">{{ activeCompanyId && activeCompany.name ? activeCompany.name : '--' }}</span>
              </div>

              <div class="header-right flex flex-1 flex-je">
                <div class="area-wrap flex flex-dc">
                  <span class="label">預計利潤/m</span>
                  <span class="value">{{ activeCompanyId && activeCompany.projectedRevenue ? (activeCompany.projectedRevenue + " " + activeCompany.currency) : "--" }}</span>
                </div>
                <div class="area-wrap flex flex-dc">
                  <span class="label">預計貨量</span>
                  <span class="value">{{ activeCompanyId && activeCompany.projectedShipmentVolume ? (activeCompany.projectedShipmentVolume + " " + activeCompany.volumeUnit) : "--" }}</span>
                </div>
                <div class="area-wrap flex flex-dc">
                  <span class="label">預計合作月份</span>
                  <span class="value">{{ activeCompanyId && activeCompany.expectedDate ? activeCompany.expectedDate.substring(0,7) : "--" }}</span>
                </div>
                <div class="area-wrap flex flex-ac" style="padding-right:10%">
                  <div>
                    <img v-if="activeCompanyId && activeCompany.customerManager && activeCompany.customerManager.faceUrl"
                         :src="activeCompany.customerManager.faceUrl" class="user">
                    <img v-else src="@/assets/images/logo.png" class="user">
                  </div>
                  <div class="flex flex-dc">
                    <span class="label">業務經理</span>
                    <span class="value text-primary">{{ activeCompanyId && activeCompany.customerManager && activeCompany.customerManager.nickname ? activeCompany.customerManager.nickname : '--' }}</span>
                  </div>
                  <div class="star-wrap">
                    <i style="color: gold" :class="{'el-icon-star-on' : activeCompanyId && activeCompany.isFavorite,
                     'el-icon-star-off': !activeCompanyId || !activeCompany.isFavorite}"></i>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-content flex">
              <div class="flex flex-ac">
                <div class="label flex flex-ac fw" style="font-size:calc(0.6vw + 6px)">
                  <i class="el-icon-time mr5" style="font-size:calc(1vw + 10px)"></i>
                  <span class="mr10">狀態:</span>
                </div>
                <div>
                  <span class="el-dropdown-link">
                    {{ activeCompanyId && activeCompany.status ? activeCompany.status : '--' }}
                  </span>

                </div>
              </div>

              <div class="list-wrap flex-1 flex flex-ac flex-jc">
                <div class="list-item flex flex-ac flex-jc" :class="{'active': activeCompany?.companyProgress?.isResearch}">調研</div>
                <div class="list-item flex flex-ac flex-jc" :class="{'active': activeCompany?.companyProgress?.isEmail}">郵件</div>
                <div class="list-item flex flex-ac flex-jc" :class="{'active': activeCompany?.companyProgress?.isPhone}">電話</div>
                <div class="list-item flex flex-ac flex-jc" :class="{'active': activeCompany?.companyProgress?.isSocial}">社群</div>
                <div class="list-item flex flex-ac flex-jc" :class="{'active': activeCompany?.companyProgress?.isVideo}">影片</div>
                <div class="list-item flex flex-ac flex-jc" :class="{'active': activeCompany?.companyProgress?.isVisit}">拜訪</div>
                <div class="list-item flex flex-ac flex-jc" :class="{'active': activeCompany?.companyProgress?.isInviteVisit}">參訪</div>
                <div class="list-item flex flex-ac flex-jc" :class="{'active': activeCompany?.companyProgress?.isSolution}">解決<br>方案</div>
                <div class="list-item flex flex-ac flex-jc" :class="{'active': activeCompany?.companyProgress?.isDeal}">成交</div>
              </div>
            </div>
          </div>

          <div class="flex-1 flex">
            <div class="flex-1 flex flex-dc">
              <div class="card-group flex flex-dc">
                <div class="card-header flex flex-ac flex-jb is-border">
                  <div class="title flex flex-ac">聯繫人資訊</div>
                  <div class="tooltip flex flex-ac">
                    <i class="el-icon-plus" @click="newContact"></i>
                    <router-link :to="`company?id=${activeCompanyId}`"><i class="el-icon-office-building"></i></router-link>
                  </div>
                </div>
                <div class="card-content flex flex-ww flex-js">

                  <div v-if="addNew" class="form-card-wrap">
                    <div class="form-wrap global-form-wrap">
                      <div class="flex flex-1">
                        <div class="left flex-dc">
                          <div class="form-group flex flex-ac">
                            <div class="form-label">*中文名</div>
                            <div class="form-control flex-1">
                              <el-input v-model="newItem.name" placeholder="请输入内容" size="small"></el-input>
                            </div>
                          </div>
                          <div class="form-group flex flex-ac">
                            <div class="form-label">*英文名</div>
                            <div class="form-control flex-1">
                              <el-input v-model="newItem.nameEn" placeholder="请输入内容" size="small"></el-input>
                            </div>
                          </div>
                        </div>

                        <div class="pic flex flex-ac ml15">
                          <img src="@/assets/images/logo.png" width="60px">
                        </div>
                        <div class="btn flex flex-dc flex-ac flex-js ml15">
                          <el-upload
                              ref="avatarUpload_new"
                              :action="uploadUrl"
                              :data="templateUploadData"
                              :headers="uploadHeaders"
                              :on-success="handleUploadAvatarSuccess"
                              :on-error="handleUploadAvatarError"
                              :show-file-list="false"
                              :limit="1"
                              :file-list="avatarList">
                            <i class="el-icon-upload pointer" style="font-size:calc(0.8vw + 8px); color:#555"></i>
                          </el-upload>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">*職稱</div>
                        <div class="form-control flex-1">
                          <el-input v-model="newItem.title" placeholder="请输入内容" size="small"></el-input>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">*權力類別</div>
                        <div class="form-control flex-1">
                          <el-select v-model="newItem.powerType" placeholder="请选择" size="small" style="width:100%">
                            <el-option label="引薦者" value="REFERRAL"></el-option>
                            <el-option label="影響者" value="INFLUENCE"></el-option>
                            <el-option label="決策者" value="DECISION"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">*責任類別</div>
                        <div class="form-control flex-1">
                          <el-select v-model="newItem.position" placeholder="请选择" size="small" style="width:100%">
                            <el-option label="操作者" value="OPERATOR"></el-option>
                            <el-option label="主管" value="SUPERVISOR"></el-option>
                            <el-option label="經營" value="BUSINESS"></el-option>
                            <el-option label="經理" value="MANGER"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">信箱</div>
                        <div class="form-control flex-1">
                          <el-input v-model="newItem.email" placeholder="请输入内容" size="small"></el-input>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">*性別</div>
                        <div class="form-control flex-1">
                          <el-select v-model="newItem.gender" placeholder="请选择" size="small" style="width:100%">
                            <el-option label="未知" :value="0"></el-option>
                            <el-option label="男" :value="1"></el-option>
                            <el-option label="女" :value="2"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">生日</div>
                        <div class="form-control flex-1">
                          <el-date-picker
                              v-model="newItem.birthday"
                              type="date"
                              size="small"
                              placeholder="请选择"
                              style="width: 100%;"
                              value-format="yyyy-MM-dd">
                          </el-date-picker>

                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">個性</div>
                        <div class="form-control flex-1">
                          <el-select v-model="newItem.personality" multiple filterable allow-create default-first-option
                                     placeholder="请选择" size="small" style="width:100%">
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">嗜好</div>
                        <div class="form-control flex-1">
                          <el-select v-model="newItem.hobbies" multiple filterable allow-create default-first-option
                                     placeholder="请选择"
                                     size="small" style="width:100%">
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">教育</div>
                        <div class="form-control flex-1">
                          <el-select v-model="newItem.education" filterable allow-create default-first-option placeholder="请选择"
                                     size="small" style="width:100%">
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">家庭</div>
                        <div class="form-control flex-1">
                          <el-select v-model="newItem.maritalStatus" filterable default-first-option
                                     placeholder="请选择" size="small" style="width:100%">
                            <el-option label="未婚" value="UNMARRIED"></el-option>
                            <el-option label="已婚" value="MARRIED"></el-option>
                            <el-option label="離異" value="DIVORCE"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">居住地</div>
                        <div class="form-control flex-1">
                          <el-input v-model="newItem.address" placeholder="请输入内容" size="small"></el-input>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">工作經歷</div>
                        <div class="form-control flex-1">
                          <el-select v-model="newItem.workExperience" multiple filterable allow-create default-first-option
                                     placeholder="请选择" size="small" style="width:100%">
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">來源</div>
                        <div class="form-control flex-1">
                          <el-select v-model="newItem.source" filterable default-first-option placeholder="请选择"
                                     size="small" style="width:100%">
                            <el-option label="馬太" value="MATTHEWPI"></el-option>
                            <el-option label="網路" value="INTERNET"></el-option>
                            <el-option label="電話開發" value="PHONE"></el-option>
                            <el-option label="客戶介紹" value="INTRODUCTION"></el-option>
                            <el-option label="內部資料" value="INTERNAL_MATERIALS"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div v-for="(im, imIndex) in newItemImList" :key="'newItemIm_' + imIndex" class="form-group flex flex-ac">
                        <div class="form-label flex flex-jb">
                          <span v-if="imIndex === 0">聯絡方式</span>
                          <span v-else> </span>
                          <i v-if="imIndex === 0" class="el-icon-plus fw pointer mr10" @click="newItemImList.push({})"></i>
                          <i v-if="newItemImList.length > 1" class="el-icon-minus fw pointer mr10"
                             @click="newItemImList.splice(imIndex, 1)"></i>
                        </div>
                        <div class="form-control flex-1 flex">
                          <el-select v-model="im.type" filterable allow-create default-first-option placeholder="请选择"
                                     size="small" class="mr10" style="width:40%">
                            <el-option label="Phone" value="PHONE"></el-option>
                            <el-option label="WeChat" value="WECHAT"></el-option>
                            <el-option label="Line" value="LINE"></el-option>
                            <el-option label="X(Twitter)" value="TWITTER"></el-option>
                            <el-option label="Facebook" value="FACEBOOK"></el-option>
                            <el-option label="Linkedin" value="LINKEDIN"></el-option>
                            <el-option label="分機" value="TEL_EXT"></el-option>
                          </el-select>
                          <el-input class="flex-1" v-model="im.value" placeholder="请输入内容" size="small"></el-input>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac flex-je">
                        <el-button type="success" size="small" @click="save(newItem)">Save</el-button>
                        <el-button type="info" size="small" @click="addNew=false">Cancels</el-button>
                      </div>
                    </div>
                  </div>

                  <div v-for="(contact, index) in contactList" :key="contact.id"
                       :class="{'form-card-wrap': true, active: activeContactId === contact.id}">
                    <div class="form-wrap global-form-wrap">
                      <div class="flex flex-1">
                        <div class="left flex-dc">
                          <div class="form-group flex flex-ac">
                            <div class="form-label">*中文名</div>
                            <div class="form-control flex-1">
                              <el-input v-model="contact.name" placeholder="请输入内容" size="small"
                                        :disabled="!cardStatus['_' + index].isEdit"></el-input>
                            </div>
                          </div>
                          <div class="form-group flex flex-ac">
                            <div class="form-label">*英文名</div>
                            <div class="form-control flex-1">
                              <el-input v-model="contact.nameEn" placeholder="请输入内容" size="small"
                                        :disabled="!cardStatus['_' + index].isEdit"></el-input>
                            </div>
                          </div>
                        </div>

                        <div class="pic flex flex-ac ml15">
                          <img v-if="contact.avatarUrl" :src="contact.avatarUrl" width="60px">
                          <img v-else src="@/assets/images/logo.png" width="60px">
                        </div>
                        <div class="btn flex flex-dc flex-ac flex-js ml15">
                          <i class="el-icon-edit pointer" style="font-size:calc(0.8vw + 8px); color:#555"
                             :class="{'text-success': cardStatus['_' + index].isEdit}"
                             @click="cardStatus['_' + index].isEdit = !cardStatus['_' + index].isEdit"></i>
                          <el-upload
                              :ref="'avatarUpload_' + index"
                              v-show="cardStatus['_' + index].isEdit"
                              :action="uploadUrl"
                              :data="templateUploadData"
                              :headers="uploadHeaders"
                              :on-success="handleUploadAvatarSuccess"
                              :on-error="handleUploadAvatarError"
                              :show-file-list="false"
                              :limit="1"
                              :file-list="avatarList">
                            <i class="el-icon-upload pointer" style="font-size:calc(0.8vw + 8px); color:#555"></i>
                          </el-upload>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">*職稱</div>
                        <div class="form-control flex-1">
                          <el-input v-model="contact.title" placeholder="请输入内容" size="small"
                                    :disabled="!cardStatus['_' + index].isEdit"></el-input>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">*權力類別</div>
                        <div class="form-control flex-1">
                          <el-select v-model="contact.powerType" placeholder="请选择" size="small"
                                     :disabled="!cardStatus['_' + index].isEdit" style="width:100%">
                            <el-option label="引薦者" value="REFERRAL"></el-option>
                            <el-option label="影響者" value="INFLUENCE"></el-option>
                            <el-option label="決策者" value="DECISION"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">*責任類別</div>
                        <div class="form-control flex-1">
                          <el-select v-model="contact.position" placeholder="请选择" size="small"
                                     :disabled="!cardStatus['_' + index].isEdit" style="width:100%">
                            <el-option label="操作者" value="OPERATOR"></el-option>
                            <el-option label="主管" value="SUPERVISOR"></el-option>
                            <el-option label="經營" value="BUSINESS"></el-option>
                            <el-option label="經理" value="MANGER"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">信箱</div>
                        <div class="form-control flex-1">
                          <el-input v-model="contact.email" placeholder="请输入内容" size="small"
                                    :disabled="!cardStatus['_' + index].isEdit"></el-input>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">*性別</div>
                        <div class="form-control flex-1">
                          <el-select v-model="contact.gender" placeholder="请选择" size="small"
                                     :disabled="!cardStatus['_' + index].isEdit" style="width:100%">
                            <el-option label="未知" :value="0"></el-option>
                            <el-option label="男" :value="1"></el-option>
                            <el-option label="女" :value="2"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">生日</div>
                        <div class="form-control flex-1">
                          <el-date-picker
                              v-model="contact.birthday"
                              type="date"
                              size="small"
                              placeholder="请选择"
                              style="width: 100%;"
                              value-format="yyyy-MM-dd"
                              :disabled="!cardStatus['_' + index].isEdit">
                          </el-date-picker>

                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">個性</div>
                        <div class="form-control flex-1">
                          <el-select v-model="contact.personality" multiple filterable allow-create default-first-option
                                     placeholder="请选择" size="small" :disabled="!cardStatus['_' + index].isEdit"
                                     style="width:100%">
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">嗜好</div>
                        <div class="form-control flex-1">
                          <el-select v-model="contact.hobbies" multiple filterable allow-create default-first-option placeholder="请选择"
                                     size="small" :disabled="!cardStatus['_' + index].isEdit" style="width:100%">
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">教育</div>
                        <div class="form-control flex-1">
                          <el-select v-model="contact.education" filterable allow-create default-first-option placeholder="请选择"
                                     size="small" :disabled="!cardStatus['_' + index].isEdit" style="width:100%">
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">家庭</div>
                        <div class="form-control flex-1">
                          <el-select v-model="contact.maritalStatus" filterable default-first-option
                                     placeholder="请选择" size="small" :disabled="!cardStatus['_' + index].isEdit"
                                     style="width:100%">
                            <el-option label="未婚" value="UNMARRIED"></el-option>
                            <el-option label="已婚" value="MARRIED"></el-option>
                            <el-option label="離異" value="DIVORCE"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">居住地</div>
                        <div class="form-control flex-1">
                          <el-input v-model="contact.address" placeholder="请输入内容" size="small"
                                    :disabled="!cardStatus['_' + index].isEdit"></el-input>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">工作經歷</div>
                        <div class="form-control flex-1">
                          <el-select v-model="contact.workExperience" multiple filterable allow-create default-first-option
                                     placeholder="请选择" size="small" :disabled="!cardStatus['_' + index].isEdit"
                                     style="width:100%">
                          </el-select>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac">
                        <div class="form-label">來源</div>
                        <div class="form-control flex-1">
                          <el-select v-model="contact.source" filterable default-first-option placeholder="请选择"
                                     size="small" style="width:100%" :disabled="!cardStatus['_' + index].isEdit">
                            <el-option label="馬太" value="MATTHEWPI"></el-option>
                            <el-option label="網路" value="INTERNET"></el-option>
                            <el-option label="電話開發" value="PHONE"></el-option>
                            <el-option label="客戶介紹" value="INTRODUCTION"></el-option>
                            <el-option label="內部資料" value="INTERNAL_MATERIALS"></el-option>
                          </el-select>
                        </div>
                      </div>

                      <div v-for="(im, imIndex) in cardStatus['_' + index].imList" :key="index + 'im_' + imIndex"  class="form-group flex flex-ac">
                        <div class="form-label flex flex-jb">
                          <span v-if="imIndex === 0">聯絡方式</span>
                          <span v-else> </span>
                          <i v-if="imIndex === 0" class="el-icon-plus fw pointer mr10" @click="cardStatus['_' + index].imList.push({})"></i>
                          <i v-if="cardStatus['_' + index].imList.length > 1" class="el-icon-minus fw pointer mr10" @click="cardStatus['_' + index].imList.splice(imIndex, 1)"></i>
                        </div>
                        <div class="form-control flex-1 flex">
                          <el-select v-model="im.type" filterable allow-create default-first-option placeholder="请选择"
                                     size="small" :disabled="!cardStatus['_' + index].isEdit" class="mr10" style="width:40%">
                            <el-option label="Phone" value="PHONE"></el-option>
                            <el-option label="WeChat" value="WECHAT"></el-option>
                            <el-option label="Line" value="LINE"></el-option>
                            <el-option label="X(Twitter)" value="TWITTER"></el-option>
                            <el-option label="Facebook" value="FACEBOOK"></el-option>
                            <el-option label="Linkedin" value="LINKEDIN"></el-option>
                            <el-option label="分機" value="TEL_EXT"></el-option>
                          </el-select>
                          <el-input class="flex-1" v-model="im.value" placeholder="请输入内容" size="small"
                                    :disabled="!cardStatus['_' + index].isEdit"></el-input>
                        </div>
                      </div>

                      <div class="form-group flex flex-ac flex-je" v-show="cardStatus['_' + index].isEdit">
                        <el-button type="success" size="small" @click="update(contact, index)">Save</el-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- right-wrap end -->
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import '../../styles/element-variables.scss';

  .page ::v-deep {
    position:absolute;
    left:0;
    top:0;
    right:0;
    bottom:0;
    overflow:hidden;
    background-color:#f5f5f5;

    .main-wrap {
      height:100%;
      overflow:hidden;

      .left-wrap {
        width:calc(16vw);
        min-width:200px;
        max-width:400px;
        border-right:1px solid #999;
        padding:15px;

        .menu-list-wrap {
          background-color:#fff;
          border-radius:5px;
          box-shadow:0 5px 5px rgba(#000, 0.2);
          overflow:hidden;

          .tooltip-wrap {
            border-bottom:1px solid #999;

            .el-input-group__prepend,
            .el-input__inner,
            .el-input-group__append {
              background-color:#fff;
              border:none;

              i {
                font-size:calc(0.7vw + 7px);
              }
            }
          }

          .list-wrap {
            overflow-y:auto;

            .list-group {
              border-bottom:1px solid #999;
              padding:15px;
              cursor:pointer;

              &:last-child {
                border:none;
              }

              &.active {
                background-color: rgba($--color-primary, 0.1);
              }

              img {
                width:calc(1vw + 10px);
              }

              .info-wrap {
                margin-left:10px;
                font-size:calc(0.5vw + 5px);

                .title {
                  color:$--color-primary;
                  font-weight:bold;
                }
              }
            }
          }
        }

        .btn-wrap {
          padding:20px 0;
          background-color:$--color-primary;
          color:#fff;
          box-shadow:0 5px 5px rgba(#000, 0.2);
          margin-top:15px;
          border-radius:5px;

          i {
            font-size:calc(1vw + 10px);
            cursor:pointer;
          }
        }
      }

      .right-wrap {
        margin-left:15px;
        padding:15px 0;

        .card-wrap {
          height:100%;
          overflow-y:auto;

          .card-group {
            box-shadow:0 5px 5px rgba(#000, 0.2);
            margin:0 15px 15px 0;
            background-color:#fff;
            padding:15px;
            border-radius:5px;

            .card-header {

              &.is-border {
                border-bottom:1px solid #999;
                padding-bottom:5px;
              }

              i {
                font-size:calc(0.8vw + 8px);
                cursor:pointer;
                margin-right:10px;
                color:#555;
              }

              .title {
                color:$--color-primary;
                font-size:calc(0.6vw + 6px);
                font-weight:bold;

                .tag {
                  width:calc(1vw + 10px);
                  height:calc(1vw + 10px);
                  color:#fff;
                  background-color: $--color-primary;
                  border-radius:100%;
                  margin-left:10px;
                  font-size:calc(0.5vw + 5px);
                }
              }

              .header-left {
                img {
                  width:calc(1.5vw + 15px);
                }

                .company-name {
                  font-size:calc(0.7vw + 7px);
                  font-weight:bold;
                  color:$--color-primary;
                  margin-left:10px;
                }
              }

              .header-right {

                .area-wrap {
                  border-right:1px solid #ccc;
                  padding:0 5%;
                  position:relative;
                  font-size:calc(0.5vw + 5px);

                  &:last-child {
                    border:none;
                  }

                  .user {
                    width:calc(1.2vw + 12px);
                    margin-right: 10px;
                  }

                  .label {
                    color:#999;
                  }

                  .value {
                    font-weight:bold;
                  }
                }

                .star-wrap {
                  position:absolute;
                  right:0;
                  top:5px;

                  i {
                    font-size:calc(1vw + 10px);
                    cursor:pointer;
                  }
                }
              }
            }

            .card-content {
              margin-top:10px;

              .form-card-wrap {
                padding:15px;
                margin:10px;
                box-shadow:0 5px 5px rgba(#000, 0.2);
                border-radius:5px;
                border:1px solid #f5f5f5;
                max-width:450px;

                &.active {
                  background-color:rgba($--color-primary, 0.1);
                }
              }

              .list-wrap {
                .list-item {
                  margin-left:2%;
                  width:calc(2.2vw + 22px);
                  height:calc(2.2vw + 22px);
                  border-radius:100%;
                  border:4px solid #999;
                  color:#999;
                  font-size:calc(0.6vw + 5px);
                  font-weight: bold;
                  cursor:pointer;

                  &.active {
                    background-color:$--color-primary;
                    color:#fff;
                    border-color:$--color-primary;
                    box-shadow:0 0 5px #555;
                    text-shadow: 0 0 15px #fff;
                  }
                }
              }
            }
          }
        }
      }

    }
  }
</style>

<script>
import * as contactApi from "@/apis/contact";
import * as companyApi from "@/apis/company";
import {getToken} from "@/utils/auth";

export default {
  data() {
    return {
      loading: false,
      editCompany: false,
      category: {
        _1: false,
        _2: false,
        _3: false,
        _4: false,
        _5: false,
        _6: false,
        _7: false,
        _8: false,
        _9: false
      },
      cardStatus: {
        _1: {
          isEdit: false
        },
        _2: {
          isEdit: false
        },
        _3: {
          isEdit: false
        }
      },
      search: '',
      addNew: false,
      newItem: null,
      newItemImList: [{}],
      /**
       * 左侧搜索的列表
       */
      simpleContactList: [],
      activeCompanyId: null,
      activeContactId: null,
      activeCompany: null,
      activeContact: {},
      /**
       *正式列表
       */
      contactList: [],
      uploadUrl: process.env.VUE_APP_BASE_API + '/basic/info/upload',
      templateUploadData: {
        typeId: 50
      },
      uploadHeaders: {
        'S-Token': getToken()
      },
      avatarList: [],
      avatar: null,
    }
  },
  created() {
    this.init();
  },
  watch: {
    search: function () {
      this.getSimpleContactList()
    }
  },
  methods: {
    init() {
      this.getSimpleContactList();
    },
    /**
     * 获取左侧联系人列表
     */
    getSimpleContactList() {
      this.loading = true;
      this.avatar = null;
      contactApi.listSimple(this.search).then(data => {
        this.simpleContactList = data;
        if(this.simpleContactList && this.simpleContactList.length > 0) {
          if(this.activeContactId) {
            this.loadContact(this.activeContact);
          }else{
            this.activeContactId = null;
            this.activeContact = {};

            this.loadContact(this.simpleContactList[0]);
          }
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    /**
     * 获取详情
     */
    loadContact(contact) {
      this.loading = true;
      this.activeContact = contact;
      this.activeContactId = contact.id;
      this.activeCompanyId = contact.companyId;
      this.loadCompany(this.activeCompanyId);
      contactApi.listByCompanyId(this.activeCompanyId).then((data) => {
        const cardStatus = {}
        for (let index = 0; index < data.length; index++) {
          cardStatus['_' + index] = {
            isEdit: false,
            imList: [{}]
          }
        }
        this.cardStatus = cardStatus;

        // 把选中的提到第一个
        data.sort((a, b) => {
          if(a.id === contact.id) {
            return -1;
          }else if(b.id === contact.id) {
            return 1;
          }

          return 0;
        })

        this.contactList = data;
        for (let index = 0; index < data.length; index++) {
          if(data[index].im && data[index].im.length > 0){
            cardStatus['_' + index].imList = data[index].im
          }
        }
      }).finally(() => {
        this.loading = false;
      })
    },
    /**
     * 获取公司详情
     * @param id
     */
    loadCompany(id) {
      this.activeCompanyId = id;
      this.activeCompany = {};
      companyApi.get(id).then((data) => {
        this.activeCompany = data;
      })
    },
    newContact() {
      this.newItem = {}
      this.newItemImList = [{}]
      this.addNew = true;
    },
    save() {
      this.loading = true;

      // 格式化下 im 列表
      const imList = this.newItemImList.filter(item => item.type && item.value);
      this.newItem.im = JSON.stringify(imList);

      if(this.avatar){
        this.newItem.avatarUrl = this.avatar;
      }

      // 新增
      contactApi.add(this.activeCompanyId, this.newItem).then((data) => {
        this.addNew = false;
        this.activeContactId = data;
        this.activeContact = this.newItem;
        this.activeContact.companyId = this.activeCompanyId;
        this.getSimpleContactList();
      }).catch(() => {
        this.loading = false;
      })
    },
    update(contact, index) {
      this.loading = true;

      // 格式化下 im 列表
      const imList = this.cardStatus['_' + index].imList.filter(item => item.type && item.value);
      contact.im = JSON.stringify(imList);

      if(this.avatar){
        contact.avatarUrl = this.avatar;
      }

      // 更新
      contactApi.update(contact).then(() => {
        this.cardStatus['_' + index].isEdit = false;
        this.getSimpleContactList();
      }).catch(() => {
        this.loading = false;
      })
    },
    handleUploadAvatarSuccess(res, file) {
      this.avatarList = [];
      if (res.code !== 0) {
        this.$message({
          message: res.msg || '文件上传失败',
          type: 'error'
        });
        return;
      }

      this.avatar = file;
    },
    handleUploadAvatarError() {
      this.avatarList = [];
    }
  }
}
</script>
